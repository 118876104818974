/*font family declaration starts*/
@font-face {
  font-family: SfProText-Regular;
  src: url(../fonts/SF\ Pro\ Text/SF-Pro-Text-Regular.otf);
}
@font-face {
  font-family: SfProText-Bold;
  src: url(../fonts/SF\ Pro\ Text/SF-Pro-Text-Bold.otf);
}
@font-face {
  font-family: SfProText-SemiBold;
  src: url(../fonts/SF\ Pro\ Text/SF-Pro-Text-Semibold.otf);
}
html {
  scroll-behavior: smooth;
}
.mob-only {
  display: none !important;
}

/*font family declaration ends*/
body {
  margin: 0;
  padding: 0;
  background: #faf8ff;
  position: relative;
  min-height: 100vh;
}
img {
  max-width: 100%;
  height: auto;
}
p,
small,
span {
  padding: 0;
  margin: 0;
  font-family: SfProText-Regular;
  cursor: default;
}
h3,
h2 {
  padding: 0;
  margin: 0;
}
button {
  cursor: pointer;
}
li {
  list-style: none;
  cursor: default;
}
.w-100 {
  width: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.mob-blk {
  display: none;
}
/* colors starts*/
:root {
  --blue: rgba(76, 27, 179, 0.94);
  --white: #f8f8ff;
  --black: #241c2d;
}
/*colors ends */
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.justify-between {
  justify-content: space-between;
}
.gap-2 {
  gap: 10px;
}
.mt-2 {
  margin-top: 4px;
}
.m-auto {
  margin: auto;
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}
.text-danger {
  color: red;
  font-size: 14px;
  display: inline-block;
}
.text-danger:first-letter {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
/*radio buttons*/

/* end only demo styles */

.radio-custom {
  opacity: 0;
  position: absolute;
}

.radio-custom,
.radio-custom-label {
  /* display: inline-block;
  vertical-align: middle;
  margin: 5px; */
  cursor: pointer;
}

.radio-custom-label {
  position: relative;
}

.radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
  content: url("../images//icon/check_small.png");
  font-family: "FontAwesome";
  background: #6c27ff;
  border: none;
  color: #bbb;
}

/* buttons starts*/

.primary-button {
  background: #6c27ff;
  color: var(--white);
  border-radius: 100px;
  font-family: SfProText-Regular;
  border: none;
  font-size: 17px;
  padding: 15px;
  width: 100%;
  max-width: 342px;
  line-height: 20px;
}
.primary-button:disabled {
  opacity: 0.7;
  cursor: default;
}
.secondary-button {
  background: #f0e9ff;
  color: var(--blue);
  border-radius: 30px;
  font-family: SfProText-Regular;
  border: transparent;
  max-width: 342px;
  font-size: 17px;
  width: 100%;
  padding: 15px;
  z-index: 999;
  line-height: 20px;
}
.transparent-button {
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  z-index: 99999;
}
.transparent-button:active {
  border: none;
  outline: none;
}
/* button ends here*/
/*tab starts*/
.tab-wrap {
  flex: 1;
}
.tab-wrap .tabs {
  background: #f3eeff;
  border: 1px solid #e2d4ff;
  border-radius: 23px;
  padding: 5px;
  display: flex;
  max-width: 225px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.tab-wrap .tabitem {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 10px 23px;
  width: 185px;
  cursor: pointer;
}
.tab-wrap .tabitem p {
  font-size: 13px;
  line-height: 16px;
  color: var(--black);
}
.tabitem.tabitem--inactive {
  background: transparent;
  box-shadow: none;
}
/*tab ends here*/
/*input fields starts */
.input-wrap {
  border: 1px solid #e2d4ff;
  backdrop-filter: blur(25px);
  border-radius: 15px;
  padding: 14px 25px;
}
.input-wrap .label {
  font-size: 13px;
  line-height: 16px;
  font-family: SfProText-SemiBold;
  color: #3c3c43;
}
.input-wrap input {
  border: transparent;
  color: #0b041a;
  background: transparent;
  padding: 0px;
  font-size: 17px;
  width: 100%;
  line-height: 21px;
  opacity: 1;
}
.input-wrap input:focus-visible {
  border: transparent;
}
textarea:focus,
input:focus,
select:focus {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: none;
}
/*input fields end here*/
/* header */
.header .logo {
  width: 32px;
  height: 30px;
}
.header .logoText {
  font-family: "Fredoka";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  cursor: pointer;
}
.header-starts {
  box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1);
}
.header {
  position: relative;
}
.header .logo-wrap {
  gap: 6px;
  padding: 0;
}

.header .menu li {
  color: #9f9ba3;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-family: SfProText-Regular;
}
.header .menu {
  gap: 12px;
}
.header .menu li button {
  padding: 10px 24px;
}
.header .menu ul {
  margin: 0;
  align-items: end;
}
.header .menu .menu-list {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 9px;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 40px;
  padding-right: 0px;
  font-size: 12px;
  border: none;
  opacity: 0.4;
  background: transparent;
  color: var(--black);
}
.header .menu .activeMenu.menu-list {
  color: var(--blue);
  font-weight: 600;
  opacity: 1;
}
.header .profile-menu {
  position: absolute;
  border-radius: 35px;
  background: white;
  width: 287px;
  z-index: 2;
  right: 0px;
  box-shadow: 0px 36px 36px rgba(125, 112, 176, 0.1);
  min-height: 334px;
}
.profile-menu .sub-content-1 {
  background: rgba(108, 39, 255, 0.9);
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 35px;
  align-items: center;
  box-sizing: border-box;
  padding: 33px;
}
.profile-menu .sub-content-1 .content-wrap p.username {
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 5px;
  padding-top: 9px;
  line-height: 20px;
  color: white;
}
.profile-menu .sub-content-1 .content-wrap p.email {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.6;
  color: white;
}
.profile-menu .content-wrap > img {
  border: 2.01709px solid #c4a8ff;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}
.profile-menu .sub-content-1 .content-wrap .button-wrap {
  padding-top: 17px;
}
.profile-menu .sub-content-1 .content-wrap .button-wrap button {
  font-weight: 500;
  font-size: 15px;
}
.profile-menu .sub-content-2 .content-wrap {
  padding: 33px;
  box-sizing: border-box;
}
.profile-menu .sub-content-2 .content-wrap ul {
  margin: 0;
  padding: 0;
}
.profile-menu .sub-content-2 .content-wrap li:not(:nth-of-type(2)) {
  padding-bottom: 22px;
  border-bottom: 1px solid #dcdbdd;
}
.profile-menu .sub-content-2 .content-wrap li:not(:nth-of-type(1)) {
  padding-top: 22px;
}
/*modal-avataar*/
.avatar-modal .modal-content {
  background: linear-gradient(
    180deg,
    #ddc4ff9c 0%,
    rgba(255, 255, 255, 0) 23.79%
  );
  background-color: white;
}
.avatar-modal .modal-body {
  padding: 0px;
}
.avatar-modal .avatar-wrap {
  padding: 20px;
}
.avatar-modal .selected-avatar {
  width: 110px;
  height: 110px;
}
.avatar-modal .choose-avatar {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: var(--black);
  margin-top: 25px;
}
.avatar-modal .select-avatar .active img {
  border-radius: 50%;
  border: 2px solid #3903ad;
}
.select-avatar img {
  height: 74px;
  width: 74px;
}
.select-avatar {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
/*modal-edit-profile*/
.edit-modal .modal-content {
  overflow-y: hidden;
}
.edit-modal .modal-body {
  padding: 0px;
}
.edit-modal .edit-profile-form,
.edit-profile-form {
  padding: 20px;
}
.edit-modal .prof-img {
  border: 2.01709px solid #c4a8ff;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  box-sizing: border-box;
}
.compact-inputfields .input-wrap:not(:nth-of-type(2)) {
  border-radius: 15px 15px 0px 0px;
}
.compact-inputfields .input-wrap:not(:nth-of-type(1)) {
  border-radius: 0px 0px 15px 15px;
}
.Modal-custom .image-wrap .transparent-button {
  color: var(--blue);
  font-weight: 600;
}
.Modal-custom .input-fields-content {
  padding-top: 26px;
}
.Modal-custom [type="radio"],
.form-control.gender [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.Modal-custom .edit-profile-form {
  max-height: 400px;
  overflow-y: scroll;
}
.Modal-custom .footer-edit {
  background: #faf8ff;
  /* Navigation-background */

  box-shadow: 0px 0px 8px rgba(101, 79, 146, 0.1);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 35px;
}
.webcam-wrap {
  position: fixed;
  z-index: 999999999;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
  background: rgba(34, 34, 34, 0.6);
}
.webcam-blk {
  background: black;
  border-radius: 20px;
  width: 730px;
  height: 380px;
  padding: 0px 80px;
  position: relative;
}
.webcam-blk.screenshot {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 380px;
  height: auto;
}

.webcam-blk .grey-bg {
  height: 100%;
  background: grey;
}
.webcam-capture-btn {
  z-index: 999999999;
  bottom: 0px;
  position: absolute;
  left: 0;
  margin: auto;
  right: 0;
}
.webcam {
  z-index: 999999999;
  position: relative;
}
.webcam-wrap .captured-photo-blk {
  border: 1px solid #ffffff;
  position: relative;
}
.rotate {
  transform: rotate(45deg);
}
.captured-photo-blk .first_b {
  background: white;
  position: absolute;
  width: 13px;
  height: 4px;
  left: 0px;
  top: 0px;
}
.captured-photo-blk .first_b2 {
  background: white;
  position: absolute;
  width: 13px;
  height: 4px;
  transform: rotate(-90deg);
  left: -4.5px;
  top: 9px;
}
.crop-rotate-text {
  color: white;
  font-weight: 600;
  font-size: 17px;
}
.crop-rotate {
  padding-bottom: 20px;
}
.captured-photo-blk .bottom_b1 {
  background: white;
  position: absolute;
  width: 12px;
  height: 4px;
  transform: rotate(-90deg);
  left: -4.5px;
  bottom: 7px;
}
.captured-photo-blk .bottom_b2 {
  background: white;
  position: absolute;
  width: 12px;
  height: 4px;
  left: 0px;
  bottom: 0px;
}
.captured-photo-blk .topright_b1 {
  right: 0;
  background: white;
  position: absolute;
  width: 12px;
  height: 4px;
  top: 0px;
}
.captured-photo-blk .topright_b2 {
  background: white;
  position: absolute;
  width: 12px;
  height: 4px;
  transform: rotate(-90deg);
  right: -4px;
  top: 6px;
}
.captured-photo-blk .bottomright_b2 {
  right: -4px;
  background: white;
  position: absolute;
  width: 12px;
  height: 4px;
  transform: rotate(-90deg);
  bottom: 6px;
}
.captured-photo-blk .bottomright_b1 {
  right: 0;
  background: white;
  position: absolute;
  width: 12px;
  height: 4px;
  bottom: 0px;
}
.captured-photo-blk .centerbottom {
  right: 0;
  background: white;
  position: absolute;
  width: 12px;
  height: 4px;
  left: 0;
  margin: auto;
  bottom: 0px;
}
.captured-photo-blk .centertop {
  right: 0;
  background: white;
  position: absolute;
  width: 12px;
  height: 4px;
  left: 0;
  margin: auto;
  top: 0px;
}
.webcam-wrap .captured {
  background: grey;
  display: flex;
  justify-content: center;
  width: 360px;

  height: 280px;
  align-items: center;
}
.webcam-wrap .captured-photo {
  border-radius: 50%;
  width: 210px;
  object-fit: cover;
  height: 210px;
}
/* IMAGE STYLES */
.form-control.gender [type="radio"] + img {
  cursor: pointer;
}
.form-control.gender [type="radio"] + label svg path {
  fill: #1b191f;
  opacity: 0.4;
}

/* CHECKED STYLES */
.form-control.gender [type="radio"]:checked + label svg path {
  fill: var(--blue);
  opacity: 1;
}
.form-control.gender [type="radio"]:checked + label:nth-of-type(2) {
  border-radius: 0;
}
.form-control.gender [type="radio"]:checked + label:nth-of-type(3) {
  border-radius: 0px 12px 12px 0px;
}
.form-control.gender {
  display: flex;
  border: 1px solid #e2d4ff;
  border-radius: 15px;
  padding: 3px;
  /* margin-bottom: 23px; */
}
.custom-border {
  height: 52px;
  width: 1px;
  background-color: #e2d4ff;
  margin-right: 2px;
  margin-left: 2px;
}
.form-control.gender [type="radio"] + label {
  border-radius: 12px 0px 0px 12px;
  padding: 14px 11px;
  height: 52px;
  opacity: 0.3;
  border-radius: 12px 0px 0px 12px;
  box-sizing: border-box;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 15px;
  border-right: 1px solid #e2d4ff;
  width: 100%;
  line-height: 18px;
  display: flex;
}
.form-control.gender [type="radio"]:checked + label {
  background: #f0e9ff;
  border-radius: 12px 0px 0px 12px;
  padding: 14px 11px;
  height: 52px;
  opacity: 1;
  box-sizing: border-box;
  color: var(--blue);
}
.form-control.gender [type="radio"]:checked + .label {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--blue);
}
/*profile photo modal*/
.profile-modal .modal-content {
  background: linear-gradient(
    180deg,
    #ddc4ff9c 0%,
    rgba(255, 255, 255, 0) 23.79%
  );
  background-color: white;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  background-color: #fff;
  max-height: 100vh;
  border-radius: 35px;
  /* height: 100%; */
  width: 80%;
  max-width: 390px;
  box-shadow: 0px 36px 36px rgba(125, 112, 176, 0.1);
  overflow-y: scroll;
  animation-name: modal-animation;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}
.modal-header h3.title {
  font-family: SfProText-Regular;
  color: #1b191f;
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
  font-size: 17px;
  line-height: 20px;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-bottom: 10px;
}

.modal-header h2 {
  margin: 0;
}

.modal-body {
  padding: 20px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
}
input[type="file"] {
  width: 90px;
  color: transparent;
}

@keyframes modal-animation {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/*header ends*/
/* layout starts*/
.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}
/*pagination*/
.pagination-wrap {
  padding-top: 55px;
}
.pagination {
  gap: 8px;
}
.pagination .prev-btn,
.pagination .next-btn {
  background: #b0fff0;
  opacity: 1;
  border: 1px solid #79ffe7;
  width: 52px;
  height: 34px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.pagination .prev-btn svg,
.pagination .next-btn svg {
  height: 45px;
  width: 23px;
}
.pagination .paginationItem {
  border: 1px solid #dcdbdd;
  background: transparent;
  border-radius: 25px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
}
.pagination .paginationItem.active {
  background: #0be8c0;
  border: 1px solid #0be8c0;
}
.pagination .dots-pagination {
  color: var(--black);
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 15px;
}
/* MODAL */
/* Background effect. It covers the entire site */
.filter-modal {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 1; /* Overlay effect: positioned over other containers */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  position: fixed; /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto; /* Enable scroll if needed */
  padding-top: 0px; /* Location of the content container */
}
/* Modal content */
.Overlay-custom {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 5;
}

.Overlay-custom.Show {
  display: block;
}

.Modal-custom h3.title {
  font-family: SfProText-Regular;
  color: #1b191f;
  font-weight: 600;
  text-align: center;
  padding-bottom: 30px;
  padding-top: 10px;
  font-size: 17px;
  line-height: 20px;
}

.Modal-custom .content-wrap .content-title {
  color: var(--black);
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.cancellation-policy {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(97, 97, 97, 0.15);
  border-radius: 15px;
  padding: 24px 16px;
}

.Modal-custom .content-wrap .content,
.cancellation-policy .content {
  color: #9f9ba3;
  font-weight: 400;
  padding-top: 16px;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.0024em;
}
.Modal-custom .footer,
.cancellation-policy-footer.footer {
  background: white;
  box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1);
  border-radius: 15px;
  margin-top: 16px;
  padding: 16px;
}
.Modal-custom .footer .complaints-wrap p,
.cancellation-policy-footer.footer .complaints-wrap p {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: var(--black);
}
.Modal-custom .footer .complaints-wrap a,
.cancellation-policy-footer.footer .complaints-wrap a {
  font-weight: 400;
  font-size: 17px;
  font-family: SfProText-Regular;

  line-height: 21px;
  letter-spacing: -0.0041em;
  color: var(--blue);
}

.filter-modal .modal-content {
  background: linear-gradient(
    180deg,
    #ddc4ff 0%,
    rgba(255, 255, 255, 0) 12.79%
  );
  background-color: white;
  border-radius: 35px;
  width: 70%; /* Width in proportion to its parent container*/
  max-width: 450px; /* Max width where it stops expanding */
  margin: auto; /* Auto margin according to the element width */
}
.filter-modal .modal-content .content-blk {
  padding: 12px 20px;
}
.filter-modal .modal-content .handle-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-modal .modal-content .handle-wrap .handle {
  background: #a7a7a7;
  opacity: 0.6;
  border-radius: 100px;
  width: 36px;
  height: 5px;
}
.filter-modal .modal-content .heading {
  font-size: 17px;
  line-height: 20px;
  color: var(--black);
  font-weight: 600;
}
.filter-modal .modal-content .main-heading {
  padding-top: 23px;
}
.filter-modal .modal-content .sub-content {
  height: 361px;
  overflow-y: scroll;
  padding-bottom: 30px;
  padding-top: 12px;
}
.filter-modal .filter-blk:not(:nth-of-type(4)) {
  border-bottom: 1px solid #dcdbdd;
  padding-bottom: 30px;
}
.filter-modal .filters-btn-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding-top: 12px;
}
.filter-btn {
  text-align: center;
  background-color: transparent;
  padding: 12px 16px;
  /* border: 1px solid #dcdbdd; */
  border: 1px solid #c4a8ff;
  border-radius: 100px;
}
.active.filter-btn {
  background-color: #f0e9ff;
  border: 1px solid #c4a8ff;
  color: var(--blue);
}
.filter-modal .filter-blk:not(:nth-of-type(1)) {
  padding-top: 24px;
}
.filter-modal .sub-content .primary-button {
  width: 100%;
  max-width: initial;
}

.layout-card {
  display: flex;
  justify-content: center;
  max-width: 440px;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0px 36px 36px rgba(125, 112, 176, 0.1);
  border-radius: 31.6194px;
  background-color: white;
  padding: 40px;
  margin-bottom: 40px;
}
/* layout ends*/
/*signup layout start*/
.signup-layout {
  display: flex;
  flex-direction: column;
}
.signup-layout .heading-layout {
  font-style: normal;
  font-weight: 700;
  font-family: SfProText-Bold;
  font-size: 34px;
  line-height: 41px;
}
.signup-layout .sub-heading {
  color: #545257;
  font-size: 17px;
  line-height: 21px;
  opacity: 0.6;
  margin-bottom: 30px;
}
.signup-layout .alert-container {
  position: absolute;
  bottom: 40px;
  margin: auto;
  left: 0;
  right: 0;
  width: 80%;
}
.signup-layout .alert-container {
  position: absolute;
  bottom: 40px;
  margin: auto;
  left: 0;
  right: 0;
  width: 80%;
}
.signup-layout .alert-container .alert {
  background: url("../images/background/alert.png") no-repeat center center;
  width: 100%;
  background-repeat: no-repeat;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  max-width: 456px;
  box-sizing: border-box;
  min-height: 61px;
  gap: 10px;
  padding: 10px 9px;
}
.signup-layout .alert-container .alert p {
  color: #1d005c;
  font-size: 15px;
  font-family: SfProText-Bold;
}
.signup-layout .alert-container .button-wrap {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.signup-layout .form-control {
  flex: 1 1;
}
.signup-layout .form-signup {
  display: flex;
  flex-direction: column;
  height: 73vh;
  margin-top: 25px;
}
.signup-layout .phone-input-wrap select {
  border: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  border-right: 1px solid #d3beff;
}
.signup-layout .phone-input-wrap .phoneInputCustom {
  padding-left: 20px;
}
/* signup layout ends here*/
/* otp layout starts*/
.otp-layout .otp-form {
  flex-direction: column;
  height: 60vh;
}
.otp-layout .otp-form .input-wrap {
  padding: 12px;
  width: 52px;
  display: flex;
  height: 68px;
  box-sizing: border-box;
  border-radius: 15px;
}
.otp-layout .otp-form .input-wrap input {
  width: 100%;
  text-align: center;
}
.otp-layout .otp-form {
  margin-top: 25px;
}
.otp-layout .otp-form > div > div {
  gap: 20px;
}
.countdown-wrap .countdown {
  color: var(--black);
  font-size: 15px;
  font-family: SfProText-Regular;
}
button.countdown {
  border: transparent;
  background: transparent;
}
.countdown-wrap {
  text-align: center;
  margin-top: 40px;
}
/* otp layout ends here*/
*::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}
/*home layout starts*/
.home-page .places-wrap::-webkit-scrollbar,
.activities-places-card .img-blk::-webkit-scrollbar,
.modal::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.home-page .places-wrap,
.activities-places-card .img-blk,
.modal::-webkit-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.home-wrap {
  background-image: url("../images/background/background.webp");

  background-repeat: no-repeat;
  background-position: center;
  height: 362px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-wrap .heading {
  width: 50%;
}
.home-wrap .heading h2 {
  font-weight: 700;
  font-size: 42px;
  color: white;
  font-family: SfProText-Regular;
}
.home-wrap .with-text {
  color: #ffffff;
  font-size: 20px;
  line-height: 24px;
  padding-top: 8px;
  opacity: 0.6;
}
.home-page {
  padding-bottom: 55px;
}
.home-page .active-trip-home {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: -50px;
}
.home-page .sub-blk {
  background: #ffffff;
  box-shadow: 0px 36px 36px rgba(125, 112, 176, 0);
  /* backdrop-filter: blur(30px); */
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 15px;
  padding: 20px 36px;
  /* flex-grow: 0;
  flex-shrink: 0; */
  width: 80%;
  margin-right: 16px;
  /* margin-top: -50px; */
}
.home-page .sub-blk .plan-trip {
  gap: 24px;
  justify-content: space-between;
}
.home-page .sub-blk .plan-trip .icon img {
  width: 48px;
  height: 48px;
}
.home-page .sub-blk .plan-trip .text .main-text {
  color: var(--black);
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}
.home-page .sub-blk .plan-trip .text .sub-text {
  color: var(--black);
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  opacity: 0.6;
}
.home-page .countries-tab {
  display: flex;
  padding-top: 30px;
  gap: 12px;
  align-items: center;
}
.home-page .countries-tab .country-btn {
  box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1);
  border-radius: 30px;
  border: 1px solid #dcdbdd;
  background: white;
  display: flex;
  padding: 8px 14px;
  align-items: center;
  color: var(--black);
  font-family: SfProText-Regular;
  opacity: 0.4;
  gap: 10px;
}
.home-page .countries-tab .active.country-btn {
  border: 1px solid #6c27ff;
  opacity: 1;
}
.home-page .places-wrap {
  padding-top: 12px;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.home-page .places-wrap p {
  white-space: normal;
}
.home-page .places-heading p {
  color: var(--black);
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-top: 27px;
}
.home-page .places-wrap .places-card {
  width: 192px;
  /* flex-basis: 33.333%; */
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 16px;
  height: 267px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(132, 112, 176, 0);
  border-radius: 15px;
}
.home-page .places-wrap .places-card > img {
  box-shadow: inset 0px 0px 24px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  height: 128px;
  width: 192px;
}

.home-page .places-card .places-section {
  padding: 16px;
}
.home-page .places-card .places-section .name {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
  color: var(--black);
}
.home-page .places-card .places-section .desc {
  font-weight: 400;
  font-size: 13px;
  padding-top: 4px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #9f9ba3;
}
.home-page .places-card .places-section .city-price {
  padding-top: 12px;
}
.home-page .places-card .places-section .city-price p {
  color: var(--black);
  font-size: 15px;
  line-height: 21px;
  /* text-align: center; */
  letter-spacing: -0.0024em;
}
.home-page .places-section .content {
  gap: 8px;
  align-items: center;
}
.home-page .places-section .content .typeimg {
  width: 12px;
  height: 12px;
  padding-top: 4px;
}
.home-page .show-more-card {
  background: #f0e9ff;
  border-radius: 15px;
  height: 267px;
  width: 192px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 16px;
}
.home-page .show-more-card .wrap {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: transparent;
  border: transparent;
}
.home-page .show-more-card p {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  padding-top: 12px;
  color: #6c27ff;
}
.home-page .events-card {
  width: 332px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  height: 136px;
  margin-right: 16px;
  padding: 20px 24px;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}
.home-page .events-card .events-section .name {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-family: SfProText-Bold;
}
.home-page .events-card .events-section .date {
  font-weight: 400;
  font-size: 15px;
  opacity: 0.5;
  line-height: 21px;
  color: #ffffff;
}
.home-page .events-card .events-section .events {
  position: absolute;
  bottom: 20px;
}
.home-page .events-card .events-section .events p {
  font-weight: 300;
  font-size: 13px;
  line-height: 21px;
  color: #ffffff;
}
.show-more-card.events {
  width: 332px;
  height: 136px;
  flex-grow: 0;
  flex-shrink: 0;
}
.home-page section {
  padding-top: 30px;
}
.bottom-menu {
  display: none;
}
/*home layout ends here */
/*activity layout starts */
.activities-started .activities-subheader {
  background: rgba(108, 39, 255, 0.9);
  backdrop-filter: blur(5px);
  border-radius: 0px 0px 35px 35px;
  padding: 18px;
  box-sizing: border-box;
}
.activities-started .activities-tab {
  gap: 40px;
}
.activities-started .activities-tab button {
  border: none;
  background: transparent;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  opacity: 0.4;
}
.activities-started .activities-tab button:hover {
  opacity: 1;
}
.activities-started .activities-tab button.active {
  opacity: 1;
}
.activities-started .activities-info {
  display: flex;
  padding-top: 22px;
  justify-content: space-between;
  padding-bottom: 28px;
}
.activities-started .activities-info .places-count {
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: space-between;
}
.activities-started .activities-info .places-count .text-black {
  color: var(--black);
  font-size: 24px;
  margin-left: 4px;
  line-height: 24px;
}
.activities-started .activities-info .places-count .text-grey {
  color: var(--black);
  font-size: 15px;
  opacity: 0.6;
}
.activities-started .activities-info .places-count .text-grey span {
  opacity: 1;
  color: var(--black);
}
.activities-started .activities-info .divider {
  width: 1px;
  height: 20px;
  background: rgba(222, 222, 222, 0.9);
}
.activities-started .activities-info .filter button {
  width: 106px;
  padding: 9px 16px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.activities-started .activities-places-wrap {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  flex-direction: row;
  justify-content: space-between;
  row-gap: 30px;
  flex-wrap: wrap;
}
.activities-started .activities-places-wrap .activities-places-card {
  box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1);
  border-radius: 15px;
  background-color: white;
  padding: 0;
  max-width: 338px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.activities-started .activities-places-wrap .activities-places-card .img-blk {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  gap: 8px;
}
.activities-started
  .activities-places-wrap
  .activities-places-card
  .img-blk
  img {
  width: 338px;
  height: 225px;
}
.activities-started
  .activities-places-wrap
  .activities-places-card
  .content-blk {
  text-align: initial;
  padding: 16px;
}
.activities-started
  .activities-places-wrap
  .activities-places-card
  .content-blk
  .name {
  font-size: 17px;
  line-height: 20px;
  color: var(--black);
  font-family: SfProText-Bold;
}
.activities-started
  .activities-places-wrap
  .activities-places-card
  .content-blk
  .property,
.activities-places-card .content-blk .address {
  font-size: 13px;
  line-height: 16px;
  color: var(--black);
  opacity: 0.5;
}
.activities-places-card .content-blk .address {
  font-size: 15px;
}
.activities-started
  .activities-places-wrap
  .activities-places-card
  .content-blk
  .sub-content-wrap {
  gap: 11px;
  align-items: flex-start;
  padding-top: 12px;
  display: flex;
}

.activities-places-card .content-blk .cost {
  color: var(--black);
  font-size: 15px;
  font-family: SFProText-SemiBold;
}
.explore-wrap {
  display: none;
}
.activities-started .filter-mob {
  display: none;
}
.apply-btn {
  /* position: fixed;
  bottom: 0;
  left: 0; */
  background: white;
  box-shadow: 0px 0px 8px rgba(101, 79, 146, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 35px 35px 0px 0px;
  width: 100%;
  /* max-width: 450px; */
  z-index: 9;
  box-sizing: border-box;
  padding: 20px;
  /* width: 80%; */
  /* right: 0;
  margin: auto; */
}
/*my trip blk started*/
.my-trip-blk .heading p {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--black);
}
.my-trip-blk {
  margin-top: 40px;
}
.my-trip-blk .my-trip-wrap {
  margin-top: 40px;
}
.my-trip-wrap .trip-wrap {
  box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1);
  border-radius: 15px;
  padding: 27px 16px;
}
.my-trip-wrap .trip-city {
  font-weight: 700;
  font-size: 20px;
}
.trip-set-btn {
  padding: 14px;
  border: 1px solid #cac9cd;
  border-radius: 12px;
  background: transparent;
  color: #9f9ba3;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
}
.my-trip-wrap .grey-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #9f9ba3;
}
.my-trip-wrap .plan-trip {
  margin-top: 28px;
}
/* trip details started */
.trip-details-header {
  background: rgba(108, 39, 255, 0.9);
  border-radius: 0px 0px 35px 35px;
  padding: 32px 0px;
}
.trip-details-header .trip-text {
  color: white;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.trip-details-header .trip-header-wrap {
  width: 60%;
}
.trip-details .content-area {
  margin-top: 42px;
}
.trip-details .content-area .sub-content1 {
  width: 60%;
}
.trip-details .content-area .sub-content1 .card {
  background: #ffffff;
  border-radius: 15px;
  width: 665px;
  box-shadow: 0px 3.85475px 11.5642px rgba(97, 97, 97, 0.15);
}
.trip-details .sub-content1 .card .img-wrap {
  max-height: 63px;
  white-space: nowrap;
  border-radius: 15px 15px 0px 0px;
}
.trip-details .sub-content1 .card .img-wrap img {
  height: 63px;
  width: 100%;
  white-space: nowrap;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}
.trip-details .sub-content1 .card .details {
  padding: 16px;
}
.trip-details .content-area .sub-content2 {
  width: 35%;
}
.trip-details .sub-content1 .status .dots {
  width: 10px;
  border-radius: 50%;
  height: 10px;
  background: var(--blue);
}
.trip-details .sub-content1 .status .line {
  background: #f0e9ff;
  width: 1px;
  height: 157px;
}
.trip-details .sub-content1 .card .details .property-type {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-variant: all-small-caps;
  color: #241c2d;
  opacity: 0.5;
}
.trip-details .sub-content1 .card .details .name {
  padding-top: 6px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #1b191f;
}
.trip-details .sub-content1 .card .details .address {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding-top: 6px;
  color: #241c2d;
  opacity: 0.6;
}
.trip-details .sub-content1 .card .details .view-details-btn {
  width: 178px;
  height: 36px;
  background: #f0e9ff;
  border-radius: 100px;
  color: #6c27ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trip-details .sub-content1 .card .details .entry {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #1b191f;
}
.trip-details .sub-content2 .blk {
  background: #ffffff;
  box-shadow: 0px 3.85833px 7.71665px rgba(132, 112, 176, 0.1);
  border-radius: 14.4687px;
  padding: 24px 15px;
}
.trip-details .sub-content2 .recommendations-blk .title {
  color: var(--black);
  font-weight: 600;
  font-size: 16.3979px;
  line-height: 20px;
}
.trip-details .sub-content2 .recommendations-blk .recommendation-note {
  font-weight: 400;
  color: var(--black);
  opacity: 0.5;
  font-size: 14.4687px;
  line-height: 20px;
}
.trip-details .sub-content2 .recommendations-blk .category-blk {
  border: 0.964581px solid #dcdbdd;
  border-radius: 11.575px;
  padding: 15px;
  min-height: 96px;
}
.trip-details .sub-content2 .recommendations-blk .category-blk p {
  font-weight: 600;
  font-size: 14.4687px;
  line-height: 17px;
  padding-top: 10px;
  color: var(--black);
  word-break: break-word;
}
.trip-details .sub-content2 .recommendations-blk .category-wrap {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
  gap: 10px;
  margin-top: 20px;
}
.trip-details .sub-content2 .spending-blk .title {
  font-weight: 400;
  font-size: 14.4553px;
  line-height: 20px;
  color: var(--black);
  letter-spacing: -0.0024em;
}
.trip-details .sub-content2 .spending-blk .total_spending {
  font-weight: 600;
  font-size: 23.1285px;
  line-height: 28px;
  color: var(--black);
}
.trip-details .sub-content2 .total_spending {
  padding-top: 20px;
}
.trip-details .sub-content2 .left-spending {
  padding-top: 15px;
}
.trip-details .sub-content2 .left-spending .left-text {
  font-weight: 500;
  font-size: 14.4553px;
  line-height: 17px;
  color: #9f9ba3;
}
.trip-details .sub-content2 .left-spending .left-text span {
  color: var(--black);
  font-weight: 600;
}
.trip-details .sub-content2 .spending-blk .total-grey-text {
  text-align: end;
  color: #9f9ba3;
  padding-top: 10px;
}
.status-bar .progress {
  background-color: #f4f4f4;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 5px;
  width: 300px;
}

.status-bar .progress-done {
  background: #0be8c0;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}
/* .details-popup{
  border-radius: 35px;

} */
.scrollable-content {
  max-height: 500px;
  height: 100%;
  overflow: auto;
  border-radius: 35px;
}
.details-popup .modal-body {
  padding: 0;
}
.details-popup .main-content {
  max-height: 500px;
  height: 100%;
  overflow-y: scroll;
}
/* .details-popup .detail-places-card {
  width: 390px;
  background-color: rgba(250, 248, 255, 0.6);
  background-color: white;
  border-radius: 35px;

} */
.details-popup .img-blk img {
  width: 390px;
  height: 260px;
  border-radius: 35px;
}
.details-popup .main-content {
  border-radius: 35px;
}
.details-popup .content-blk {
  background: rgba(250, 248, 255, 0.6);
  background-color: white;
  /* backdrop-filter: blur(20px); */
  z-index: 9999;
  position: relative;
  /* Note: backdrop-filter has minimal browser support */
  margin-top: -50px;
  border-radius: 35px;

  padding: 15px 36px;
  /* border-radius: 35px 35px 0px 0px; */
}
.details-popup .content-blk .property {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #545257;
  opacity: 0.6;
}
.details-popup .content-blk .name {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #1b191f;
  padding-top: 4px;
}
.details-popup .content-blk .description,
.details-popup .content-blk .wrap .time {
  line-height: 21px;
  color: var(--black);
  font-weight: 400;
  font-size: 15px;
}
.details-popup .content-blk .wrap {
  padding-bottom: 16px;
  padding-top: 12px;
  /* border-bottom: 1px solid rgba(222, 222, 222, 0.9); */
}
.details-popup .get-direction {
  width: 100%;
  padding-top: 24px;
}
.details-popup .get-direction .get-direction-btn {
  background: rgba(189, 158, 255, 0.1);
  /* backdrop-filter: blur(2px); */
  /* Note: backdrop-filter has minimal browser support */
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border: none;
  font-size: 15px;
  line-height: 18px;
  color: #6c27ff;
  font-weight: 600;
  gap: 10px;
  border-radius: 15px;
}
.details-popup .content-blk .direction-wrap {
  border-bottom: 1px solid rgba(222, 222, 222, 0.9);
}
.details-popup .detail-places-card .content-blk .title-address {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: var(--black);
}
.details-popup .detail-places-card .entry-wrap {
  border: 1px solid rgba(222, 222, 222, 0.9);
  padding: 12px;
  border-radius: 10px;
}
.details-popup .detail-places-card .content-blk .wrap .entry {
  font-size: 17px;
  line-height: 21px;
  color: #1b191f;
}
.details-popup .detail-places-card .content-blk .wrap .price {
  font-size: 17px;
  line-height: 21px;
  color: #1b191f;
  font-weight: 600;
}
.details-popup .detail-places-card .content-blk .wrap .greentext {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  text-transform: uppercase;
  color: #00b594;
}
.footer-details {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(101, 79, 146, 0.1);
  /* backdrop-filter: blur(15px); */
  display: flex;
  padding: 12px;
  justify-content: center;
  border-radius: 35px 35px;
}

.details-popup .content-blk .wrap .time {
  color: #0b041a;
  font-weight: 500;
}
.details-popup .footer-details {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(101, 79, 146, 0.1);
  /* backdrop-filter: blur(15px); */
  display: flex;
  padding: 12px;
  justify-content: center;
  border-radius: 35px 35px 0px 0px;
}
/*plan-modal*/
.plan-modal .modal-content {
  background: linear-gradient(180deg, #ddc4ff9c, hsla(0, 0%, 100%, 0) 23.79%);
  background-color: #fff;
}
.plan-modal .active.radio-wrap {
  border: 1px solid #6c27ff;
  opacity: 1;
}
.plan-modal .radio-wrap {
  padding: 16px;
  margin-bottom: 20px;
  opacity: 0.5;
  border: 1px solid #dcdbdd;
  border-radius: 15px;
}
.plan-modal p.bold-text {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: var(--black);
}
.plan-modal p.light-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.0024em;
  opacity: 0.5;
  color: var(--black);
}
/*tips-popup*/
.tipsPopup .modal-body {
  padding: 0px;
  padding-bottom: 0px;
}
.tipsPopup .modal-content {
  background: #faf8ff;
  overflow-y: hidden;
}
.tipsPopup .tipmodalHeader {
  background: rgba(108, 39, 255, 0.9);
  padding: 19px;
}
.tipsPopup .tab-wrap .content {
  height: 70vh;
  overflow: scroll;
}
.tipsPopup .tab-wrap .tabs {
  background: rgba(108, 39, 255, 0.9);
  border-radius: 0px 0px 35px 35px;
}
.tipsPopup .tipmodalHeader .title {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
}
.tipsPopup .tab-wrap .tabs {
  /* background: transparent; */
  border: none;
  justify-content: center;
  gap: 20px;
  margin: 0px auto;
  align-items: center;
  max-width: initial;
}
.tipsPopup .tab-wrap .tabitem {
  background: transparent;
  box-shadow: none;
  width: auto;
}
.tipsPopup .tab-wrap .tabitem p {
  color: white;
  opacity: 1;
}
.tipsPopup .tab-wrap .tabitem.tabitem--inactive p {
  opacity: 0.6;
}
.tipsPopup .general-tip {
  margin-top: 24px;
}
.tipsPopup .general-tip .tip-blk {
  background: url("../images/background/tip-bg.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1);
  margin-bottom: 16px;
  border-radius: 15px;
}
.tipsPopup .general-tip .tip-blk:last-of-type {
  margin-bottom: 50px;
}
.tip-blk.activity-blk {
  background: url("../images/background/activity-bg.png") !important;
}
.tipsPopup .tip-blk .id {
  font-style: italic;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: -0.06em;
  color: #e73072;
  opacity: 0.1;
}
.tipsPopup .tip-blk .note {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: var(--black);
  padding-top: 10px;
  letter-spacing: -0.0024em;
}
.tipsPopup .general-tip .heading {
  font-weight: 700;
  font-size: 24px;
  padding-top: 16px;
  line-height: 29px;
  color: var(--black);
}
.tipsPopup .general-tip .note {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  padding-top: 4px;
  word-break: break-word;
  letter-spacing: -0.0024em;
  color: #9f9ba3;
}
.general-tip .blk-language {
  box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1);
  border-radius: 15px;
  background: white;
  padding: 24px 18px;
  margin-top: 12px;
}
.mob-trip-tabs {
  display: none;
}
.general-tip .blk-language:last-of-type {
  margin-bottom: 50px;
}
.emergency-modal .modal-content {
  background-color: #faf8ff;
  border-radius: 35px;
  overflow-y: hidden;
}
.emergency-modal .emergency-wrap p.heading {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: var(--black);
}
.emergency-modal .emergency-wrap .number-wrap {
  margin-top: 17px;
}
.emergency-modal .number-wrap .blk {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(125, 112, 176, 0.1);
  border-radius: 15px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}
.emergency-modal .number-wrap.blk-2 {
  margin-bottom: 80px;
}
.emergency-modal .number-wrap .blk .hotline-number {
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 4px;
  line-height: 29px;
  color: var(--black);
}
.emergency-modal .number-wrap .blk .note {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.0024em;
  color: #9f9ba3;
}
.emergency-modal .number-wrap .blk .details .note {
  padding-top: 8px;
}
.emergency-modal .number-wrap .blk .details {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.9);
}
.emergency-modal .number-wrap .blk .translation-wrap {
  padding-top: 16px;
  gap: 16px;
}
.calendar-modal .modal-content {
  /* height: 600px;
  overflow-y: scroll; */
  background: linear-gradient(
    180deg,
    #ddc4ff 0%,
    rgba(255, 255, 255, 0) 31.03%
  );
  background-color: white;
}
.calendar-modal .calendar-body {
  height: 50vh;
  overflow-y: scroll;
  padding: 20px;
}
.calendar-modal .modal-body {
  padding: 0;
}
.calendar-modal .footer-calendar {
  background: rgba(250, 247, 255, 0.6);
  /* Navigation-background */
  padding: 8px 16px;
  box-shadow: 0px 0px 8px rgba(101, 79, 146, 0.1);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 35px;
}
.react-datepicker {
  width: 100% !important;
  background-color: transparent !important;
  border: none !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: var(--black) !important;
}
.react-datepicker__month-container {
  width: 100% !important;
}
.react-datepicker__header {
  background-color: transparent !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 1.8rem !important;
  height: 1.8rem;
}

.react-datepicker__day--in-range {
  background: #f0e9ff !important;
  border-radius: 50% !important;
}
.react-datepicker__day--range-end {
  margin-left: 0 !important;
  background: #6c27ff !important;
  border-radius: 50% !important;
  /* padding: 1px 3px !important; */
  /* justify-content: center; */
  /* display: flex; */
  color: white !important;
  box-sizing: border-box !important;
}
.react-datepicker__day--selected {
  background: #6c27ff !important;
  border-radius: 50% !important;
  /* padding: 1px 3px !important; */
  font-weight: bold !important;
  margin: 0.56rem !important;
  /* justify-content: center; */
  /* display: flex; */
  color: white !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.56rem !important;
}
.empty-state {
  /* height: 30vh; */
}
p.empty-state-text {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 40px;
}
/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  .home-wrap .heading h2 {
    font-size: 24px;
  }
  .home-content {
    border-radius: 35px 35px 0px 0px;
    background-color: white;
    position: relative;
  }
  .home-page .sub-blk {
    right: 0;
    left: 0;
    margin: auto;

    z-index: 99;
    width: 80% !important;
    position: absolute;
    top: -20px;
  }
  .home-page .countries-tab {
    padding-top: 100px;
  }
  .home-page .events-card {
    width: 272px;
  }
}

@media screen and (max-width: 600px) {
  /* body {
    overflow: hidden;
  } */
  .mob-only {
    display: block;
  }
  .desk-only {
    display: none;
  }
  /*layout*/
  .layout-card {
    border-radius: 0;
    width: auto;
    min-height: 100vh;
    box-shadow: none;
    max-width: none;
    background: #fbf9ff;
    /* overflow: hidden; */
    justify-content: flex-start;
    padding: 25px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .header {
    display: none;
  }
  .container {
    width: 100%;
  }
  .input-wrap input {
    background: transparent;
    font-size: 14px;
  }
  .phone-input-wrap .input-wrap {
    padding: 14px;
  }

  /* signup*/
  .signup-layout .form-signup {
    height: 50vh;
  }
  .signup-layout .phone-input-wrap select {
    width: 17%;
    background: transparent;
  }
  .signup-layout .phone-input-wrap .phoneInputCustom {
    padding-left: 12px;
  }
  .signup-layout .sub-heading {
    font-size: 15px;
  }
  .signup-layout .alert-container .alert {
    width: 80%;
    margin: auto;
  }
  .signup-layout .alert-container {
    position: relative;
    margin: auto;
    width: auto;
    bottom: 0;
  }
  .alert-wrap {
    position: fixed;
    bottom: 0px;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
  }
  .signup-layout .alert-container .button-wrap {
    padding-bottom: 37px;
    background: #f0e9ff;
    border-radius: 35px 35px 0px 0px;
    padding-bottom: 37px;
    box-sizing: border-box;
  }
  .signup-layout .button-wrap .primary-button {
    margin-top: 33px;
    width: 80%;
  }
  .signup-layout .alert-container .alert p {
    font-size: 12px;
  }
  .otp-layout .otp-form .input-wrap {
    width: 42px;
    height: 56px;
  }
  .otp-layout .otp-form > div > div {
    gap: 13px;
  }
  .keyboard-open {
    bottom: calc(20px + env(safe-area-inset-bottom));
  }
  /*home*/
  .home-started .container {
    padding-left: 30px;
    box-sizing: border-box;
  }
  .home-page {
    position: relative;
    padding-bottom: 110px;
  }
  .bottom-menu {
    position: fixed;
    width: 100%;
    bottom: 0;
    display: block;
    background: #faf8ff;
    box-shadow: 0px 0px 8px rgba(101, 79, 146, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 35px 35px 0px 0px;
  }
  .bottom-menu ul {
    margin: 0;
    padding: 20px 50px;
    align-items: end;
  }
  .home-page .home-content {
    margin-top: -34px;
    border-radius: 35px 35px 0px 0px;
    background: rgba(250, 248, 255, 0.9);
    /* backdrop-filter: blur(25px); */
  }
  .bottom-menu .menu-list {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    gap: 9px;
    align-items: center;
    font-size: 12px;
    border: none;
    opacity: 0.4;
    background: transparent;
    color: var(--black);
  }
  .bottom-menu .activeMenu.menu-list {
    color: var(--blue);
    font-weight: 600;
    opacity: 1;
  }
  .home-wrap {
    background-image: url("../images/background/background.webp");

    height: 272px;
    padding-bottom: 65px;
    align-items: end;
  }
  .home-wrap .heading {
    width: 70%;
  }
  .home-page .places-heading p {
    font-size: 20px;
  }
  /*activity*/
  .mob-title-wrap {
    display: flex;
  }
  .mob-title-wrap .explore {
    flex: 1;
    padding-right: 40px;
  }
  .mob-title-wrap .explore p {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: white;
  }
  .activities-started .activities-tab {
    margin: 0;
    justify-content: space-between;
    padding: 0;
    margin-top: 20px;
  }
  .activities-started .activities-info {
    display: none;
  }
  .pagination {
    display: none;
  }
  .activities-started .activities-places-wrap {
    padding-top: 24px;
    justify-content: center;
  }
  .activities-started .filter-mob {
    display: block;
    position: fixed;
    bottom: 20px;
    width: 124px;
    margin: auto;
    left: 0;
    z-index: 99;
    right: 0;
  }
  .activities-started .filter-mob .secondary-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter-modal .modal-content {
    height: auto;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
  }
  .filter-modal .modal-content .content-blk {
    overflow-y: auto;
  }
  .filter-modal .sub-content {
    padding-bottom: 140px;
  }
  .apply-btn {
    width: 100%;
    box-sizing: border-box;
  }
  /*profile page*/
  .profile-page-mob .profile-menu .sub-content-1 {
    border-radius: 0px 0px 35px 35px;
  }
  .profile-page-mob .profile-heading {
    color: white;
    font-weight: 600;
    font-size: 17px;
    padding-bottom: 27px;
  }
  .profile-page-mob .content-wrap .profile-img {
    height: 80px;
    width: 80px;
  }
  .profile-page-mob
    .profile-menu
    .sub-content-2
    .content-wrap
    li:not(:nth-of-type(2)) {
    padding-bottom: 15px;
  }
  .profile-page-mob
    .profile-menu
    .sub-content-2
    .content-wrap
    li:not(:nth-of-type(1)) {
    padding-top: 15px;
  }
  .profile-page-mob .profile-menu .sub-content-2 .content-wrap {
    background-color: white;
    box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1);
    border-radius: 15px;
    padding: 20px;
    margin: 21px 15px;
  }
  .profile-page-mob .cancellation-policy {
    margin: 16px;
  }
  .profile-page-mob .cancellation-policy-footer.footer {
    margin: 16px;
  }
  /*trip*/
  .mob-blk {
    display: block;
    background: rgba(108, 39, 255, 0.9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 0px 0px 35px 35px;
    padding: 30px;
    box-sizing: border-box;
  }
  .my-trip-blk {
    margin-top: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1);
    border-radius: 15px;
    /* padding: 33px; */
    margin: 20px;
  }
  .my-trip-blk .heading {
    display: none;
  }
  .my-trip-wrap .trip-wrap {
    flex-direction: column;
    align-items: baseline;
    gap: 20px;
  }
  .mob-trip-tabs {
    background: rgba(214, 207, 230, 0.4);
    box-shadow: 0px 36px 36px rgba(125, 112, 176, 0.1);
    backdrop-filter: blur(6px);
    border-radius: 30px;
    padding: 4px;
    width: 220px;
    display: block;
    /* display: flex; */
    bottom: 30px;
    z-index: 999999999999999999999999999999;
    position: fixed;
    left: 0;
    /* justify-content: center; */
    right: 0;
    margin: auto;
  }
  .trip-details .content-area .sub-content1 {
    width: 90%;
    margin: auto;
  }
  .mob-trip-tabs .trip-tab {
    width: 106px;
    background: #faf8ff;
    border-radius: 40px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mob-trip-tabs .trip-tab--inactive {
    background: transparent;
    border: none;
    border-radius: 0;
  }
  .mob-trip-tabs .trip-tab--inactive svg path {
    fill: #1d005c !important;
  }
  .mob-trip-tabs .trip-tab svg path {
    fill: #6c27ff;
  }
  .trip-details .content-area .sub-content2.d-none-content {
    display: none;
  }
  .trip-details .content-area .sub-content1.d-none-content {
    display: none;
  }
  .trip-details .content-area .sub-content2 {
    width: 90%;
    margin: auto;
  }
  .trip-details .content-area .sub-content1 {
    width: 90%;
  }
  .trip-details .sub-content-1 > div {
    gap: 10px;
  }
  .trip-details .content-area .sub-content1 .card {
    width: 90%;
  }
  .trip-details .sub-content1 .card .details .address {
    word-break: break-word;
    white-space: normal;
  }
  .trip-details .sub-content1 .card .details .address-wrap {
    flex-wrap: wrap;
    gap: 5px;
  }
  .trip-details .sub-content1 .card .details .entry {
    display: none;
  }
  .trip-details-header .trip-header-wrap {
    width: 100%;
  }
  .trip-details-header .header-container {
    padding: 10px 20px;
  }
}
@media screen and (max-width: 450px) {
  /*home layout*/
  .bottom-menu ul {
    padding: 20px 30px;
  }
}

/* Create profile starts here */
.r-register {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #241c2d;
  font-family: SfProText-Regular;
  margin-left: -30%;
}
.r-register-div {
  margin-left: auto;
  margin-right: auto;
}
.r-header-mob {
  display: none;
}
@media only screen and (max-width: 600px) {
  .r-header-mob {
    display: flex;
    align-items: center;
    max-width: 92%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
}
.r-label {
  font-family: SfProText-Regular;
  font-weight: 600;
  font-size: 13px;
  color: #3c3c43;
}
.r-star {
  color: #545257;
  opacity: 0.6;
}
.r-input-tag {
  background-color: transparent;
  border: none;
  width: 100%;
  box-sizing: border-box;
  font-family: SfProText-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #000000;
}

select.r-input-tag {
  text-transform: capitalize;
}
.r-input-tag::placeholder {
  font-family: SfProText-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  opacity: 0.37;
}
/*
 .r-disabled-option{
  font-family: SfProText-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  opacity: 0.37;
} 
*/
.r-form-control {
  flex: 1 1;
}
.r-form-signup {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
.r-empty-space {
  height: 22px;
}
.r-input-div-top .input-wrap {
  width: 100%;
  box-sizing: border-box;
  background: #fbf9ff;
  border: 1px solid #e2d4ff;
  backdrop-filter: blur(25px);
  border-radius: 15px 15px 0px 0px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
}
/* @media (max-width: 600px) {
  .r-input-div-top .input-wrap {
    width: auto;
    margin-right: 20px;
  }
} */
.r-input-div-top .input-wrap input {
  background: transparent;
}

.r-input-div-bottom .input-wrap {
  width: 100%;
  box-sizing: border-box;
  background: #fbf9ff;
  border: 1px solid #e2d4ff;
  backdrop-filter: blur(25px);
  border-radius: 0px 0px 15px 15px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
}
.r-input-div-bottom .input-wrap input {
  background: transparent;
}

.r-input-div-normal .input-wrap {
  width: 100%;
  box-sizing: border-box;
  background: #fbf9ff;
  border: 1px solid #e2d4ff;
  border-radius: 15px;
  backdrop-filter: blur(25px);
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
}
/* @media only screen and (max-width: 530px) {
  .r-input-div-normal .input-wrap {
    width: 100%;
    height: 45px;
    padding: 14px 20px;
  }
} */
.r-input-div-normal .input-wrap input {
  background: transparent;
}
.r-input-div-normal-dob-country {
  width: 100%;
  box-sizing: border-box;
  background: #fbf9ff;
  border: 1px solid #e2d4ff;
  border-radius: 15px;
  /* backdrop-filter: blur(25px); */
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
}

.create-layout .create-form {
  flex-direction: column;
}
.create-layout .create-form .input-wrap {
  padding: 24px;
  width: 68px;
  height: 69px;
  box-sizing: border-box;
  border-radius: 15px;
}
.create-layout .create-form {
  margin-top: 25px;
}
.create-layout .create-form > div > div {
  gap: 20px;
}

.r-text-dangerv2 {
  color: red;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
  margin-left: 20px;
}

.r-text-danger-firstname:first-letter {
  text-transform: uppercase;
}
.r-text-dangerv2:first-letter {
  text-transform: uppercase;
}
.submit-button {
  background: #6c27ff;
  color: var(--white);
  border-radius: 100px;
  font-family: SfProText-Regular;
  border: none;
  font-size: 17px;
  padding: 15px;
  width: 100%;
  line-height: 20px;
  margin-bottom: 15px;
}
/* radio  */
/* Gender input  */
.gender-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 100%;
  box-sizing: border-box;
  background: #fbf9ff;
  border: 1px solid #e2d4ff;
  border-radius: 15px;
  backdrop-filter: blur(25px);
  display: flex;
  margin-bottom: 20px;
  gap: 2px;
}

.gender-container label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #fbf9ff;
  transition: background-color 0.2s ease-in-out;
}

.label-1 {
  border-radius: 12px 0px 0px 12px;
}
.label-2 {
  border-right: 1px solid #e2d4ff;
  border-left: 1px solid #e2d4ff;
}
.label-3 {
  border-radius: 0px 12px 12px 0px;
}
.gender-container input[type="radio"] {
  appearance: none;
  display: none;
}

.gender-container .icon {
  font-size: 20px;
  margin-right: 10px;
  color: #1b191f;
}

.gender-container .label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  height: 60px;
  display: flex;
  align-items: center;
}

.home-wrap {
  background-image: url("../images/background/background.webp");

  height: 240px;
  padding-bottom: 130px;
  align-items: end;
}
.home-wrap .heading {
  width: 75%;
}
.home-page .places-heading p {
  font-size: 20px;
}

@media screen and (max-width: 450px) {
  /*home layout*/
  .bottom-menu ul {
    padding: 20px 30px;
  }
}

/* Success page starts here  */

.r-success-header {
  margin-left: 100px;
  margin-top: 30px;
}
.r-success-box {
  width: 390px;
  height: 735px;
  background: #faf8ff;
  box-shadow: 2px 8px 16px rgba(125, 112, 176, 0.1);
  border-radius: 35px;
}
.success-box {
  display: flex;
  justify-content: center;
}
.r-itinerary-saved {
  background: #6c27ff;
  box-shadow: 0px 3px 8px rgba(44, 44, 44, 0.08);
  backdrop-filter: blur(20px);
  border-radius: 35px;
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 450px) {
  .r-itinerary-saved {
    background: #6c27ff;
    box-shadow: 0px 3px 8px rgba(44, 44, 44, 0.08);
    backdrop-filter: blur(20px);
    border-radius: 0px 0px 35px 35px;
    width: 100%;
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.r-saved-succesfully-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.r-saved-succesfully-div {
  margin-top: 25px;
}
.r-sim-visa-div {
  display: flex;
  align-items: center;
  width: 100%;
}
.r-visa-div {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  margin-top: 20px;
  margin-left: 7px;
  margin-right: 15px;
  margin-bottom: 20px;
  width: 100%;
  height: 220px;
  padding: 18px;
  position: relative;
}
.r-sim-div {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 8px;
  margin-bottom: 20px;
  width: 100%;
  height: 220px;
  padding: 18px;
  position: relative;
}
.sim-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #241c2d;
}
.sim-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.6;
}
.r-guideme {
  width: 70%;
  background: #f0e9ff;
  border-radius: 100px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #6c27ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.r-corona {
  height: 120px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  width: 100%;
  padding: 18px;
  max-width: 80%;
  margin-right: 20px;
  margin-left: 20px;
  position: relative;
}
.r-knowmore {
  width: 81px;
  height: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6c27ff;
  position: absolute;
  bottom: 20px;
  left: 40px;
  cursor: pointer;
}
.r-go-to-mytrip {
  position: fixed;
  bottom: 20px;
  display: flex;
  padding: 17px 24px;
  background: #6c27ff;
  border-radius: 100px;
  font-weight: 600;
  font-size: 17px;
  color: #ffffff;
  cursor: pointer;
}
.r-plan-a-trip {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.r-plantrip-text {
  font-family: SfProText-Regular;
  font-weight: 700;
  font-size: 20px;
  color: #241c2d;
  margin-left: 5px;
}
.plantrip {
  position: relative;
}
.plantrip .floating-icon {
  position: fixed;
  bottom: 16%;
  left: 0;
  right: 15%;
  margin: auto;
  display: flex;
  align-items: end;
  justify-content: end;
}
.plan-a-trip .footer {
  background: #faf8ff;
  box-shadow: 0px 0px 8px rgba(101, 79, 146, 0.1);
  padding: 20px;
  margin-top: 80px;
}
@media screen and (max-width: 650px) {
  .alert-div-mob {
    display: flex;
    margin-top: 40px;
  }
  .alert-div {
    margin-top: 40px;
    display: none;
  }
}

@media screen and (min-width: 650px) {
  .alert-div {
    display: flex;
    margin-top: 40px;
  }
  .alert-div-mob {
    display: none;
  }
}
.addicon {
  position: absolute;
  bottom: 50px;
  left: 30%;
}

/* Events starts here  */
.r-events-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 1024px) {
  .r-events-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .r-events-grid {
    grid-template-columns: 1fr;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
  }
}

.r-events-card {
  height: 117px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.r-events-section {
  border-radius: 20px;
  color: white;
  padding: 1rem;
}
.r-gap-dot {
  padding-left: 3px;
  padding-right: 3px;
}
.r-event-name {
  text-align: left;
}
.r-innerevent-tile {
  margin-left: 8px;
}
.r-event-date {
  font-style: normal;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  opacity: 0.6;
}
.r-event-place {
  margin-top: 36px;
}
.r-event-place-text {
  font-size: 13px;
  font-weight: 300;
}
.r-event-img {
  margin-top: -30px;
}

/* Plan a trip starts here  */
.r-plan-a-trip {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.r-plantrip-text {
  font-family: SfProText-Regular;
  font-weight: 700;
  font-size: 20px;
  color: #241c2d;
  margin-left: 5px;
}

@media screen and (max-width: 450px) {
  .r-plantrip img {
    transform: scale(1.5);
    margin-right: 5px;
  }
  .r-plantrip-text {
    font-family: SfProText-Regular;
    font-weight: 700;
    font-size: 23px;
    color: #241c2d;
    margin-left: 5px;
  }
}
.r-plantrip-section {
  margin-top: 70px;
}
.activities-started, .main{
  min-height: calc(90vh - 50px);

}
.alert-div {
  margin-top: 40px;
}
.addicon {
  position: absolute;
  bottom: 50px;
  left: 30%;
}

/* location popup begins here  */
.r-location-popup {
  width: 100%;
  height: 448px;
}
.addicon button {
  background: transparent;
  border: none;
  border-radius: 200px;
}
.r-yourlocation-text {
  font-family: SfProText-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #1b191f;
}
.r-select-text {
  margin-top: 20px;
  margin-left: 3px;
  font-family: SfProText-Regular;
  line-height: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #9f9ba3;
  margin-bottom: 10px;
}

.r-reactselect {
  width: 100%;
  height: 60px;
  border-radius: 15px;
}
.r-popup-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  gap: 10px;
  background: #6c27ff;
  border-radius: 100px;
  color: white;
  width: 100%;
  border-width: 15px;
  border-color: white;
}
.r-relative-reuse {
  position: relative;
  height: 415px;
}
.r-bottom-alert-button {
  position: absolute;
  bottom: 0;
}
.r-bottom-alert-button img {
  margin-bottom: 30px;
}
.r-img-label {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px;
  border-bottom: #dcdbdd;
}
.r-img-label img {
  margin-right: 20px;
}
/* Country pop up begins here  */
.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  height: 50px;
  width: 100%;
  overflow: hidden;
}
.radio-wrapper svg {
  margin-right: 15px;
  align-items: start;
}
.custom-radio {
  position: relative;
  margin-left: auto;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}
.radio-icon {
  display: flex;
  align-items: center;
}

.custom-radio input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
}
.r-border-bottom {
  border-bottom: 1px;
  border-color: rgba(222, 218, 218, 0.9);
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.r-koreajapan-text {
  font-family: SfProText-Regular;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}
.country-radio {
  font-family: SfProText-Regular;

  flex-direction: row-reverse;
  width: 100%;
  padding-left: 10px;
  justify-content: space-between;
}
/* plan trip tab starts here  */

@media screen and (max-width: 850px) {
  .plantrip-tabdiv {
    width: 290px;
    height: 255px;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }
  .main-tab {
    width: 290px;
    border-radius: 21px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  }
}
@media screen and (min-width: 850px) {
  .plantrip-tabdiv {
    width: 712px;
    height: 200px;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }
  .main-tab {
    width: 655px;
    margin-left: 40px;
    border-radius: 21px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  }
}

.circle-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.circle {
  width: 12px;
}
.line {
  width: 1px;
}
.bg-container {
  height: 60px;
}

.view-button {
  cursor: pointer;
  border: 0;
  padding: 10px 30px;
  background-color: #f0e9ff;
  border-radius: 100px;
  width: 178px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: #6c27ff;
  outline: none;
  font-family: SfProText-Regular;
  font-weight: 500;
  font-size: 15px;
}

.icon-map {
  position: relative;
  width: 40px;
  height: 40px;
}
.button1 {
  border-radius: var(--br-81xl);
  border: 1px solid var(--primary-purple-10);
  overflow: hidden;
  background-color: transparent;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.frame-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
@media screen and (max-width: 850px) {
  .frame-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .button-parent {
    justify-content: flex-start;
    gap: 8px;
    text-align: center;
    font-size: 17;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .type-parent {
    margin-left: 18px;
  }
}
.button-parent {
  justify-content: flex-start;
  gap: 8px;
  text-align: center;
  font-size: 17;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.frame-group {
  border-radius: 0 0 var(--br-mini) var(--br-mini);
  background-color: var(--color-white);
  padding: 15px;
  box-sizing: border-box;
  justify-content: space-between;
}
.cultural-landmark {
  position: relative;
  font-family: SfProText-Regular;
  opacity: 0.5;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.gyeongbokgung-palace {
  position: relative;
  font-weight: 600;
  font-family: SfProText-Regular;
  font-size: 17px;
  line-height: 20px;
}
.tokyo-minato-city {
  align-self: stretch;
  position: relative;
  font-weight: 400;
  font-size: 13px;
  font-family: SfProText-Regular;
  line-height: 16px;
  opacity: 0.6;
}
.frame-wrapper,
.type-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.type-parent {
  flex: 1;
  flex-direction: column;
}
.frame-wrapper {
  width: 266px;
  flex-direction: row;
}
.free-entry {
  position: relative;
  font-weight: 500;
  font-weight: 500;
  font-size: 15px;
  font-family: SfProText-Regular;
  margin-top: 18px;
  line-height: 18px;
}
.frame-container {
  display: flex;
  flex-direction: row;
  padding: 0 12px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
@media screen and (min-width: 600px) {
  .r-button-wrap-mob {
    display: none;
  }
  .button-div-desk {
    display: flex;
  }
}
@media screen and (max-width: 600px) {
  .r-button-wrap-mob {
    padding-bottom: 37px;
    background: #f0e9ff;
    border-radius: 35px 35px 0px 0px;
    padding-bottom: 37px;
    box-sizing: border-box;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    padding-top: 27px;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: -25px;
    margin-right: -25px;
  }
  .button-div-desk {
    display: none;
  }
}

/* view details modal  */
.desktop1 {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 624px;
}
.viewmodal-image {
  margin-top: -50px;
  transform: scale(1.12);
}
.blur-div {
  /* background: rgba(255, 255, 255, 0.85); */
  /* backdrop-filter: blur(10px); */
  background: rgba(255, 255, 255);
  transform: scale(1.12);
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  margin-top: 5px;
  position: relative;
  border-radius: 35px 35px 0px 0px;
}
.landmark-text {
  font-family: SfProText-Regular;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #545257;
  opacity: 0.6;
}
.popup-place-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #1b191f;
  font-family: SfProText-Regular;
}

.bookmark-image {
  position: absolute;
  top: -20px;
  right: 20px;
  cursor: pointer;
}
.location-calender {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #dcdbdd;
  border-radius: 10px;
}
.location-div {
  background: #ffffff;
  margin-right: 15px;
  margin-left: 15px;
  border-bottom: 1px solid #dcdbdd;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
}
.calender-div {
  background: #ffffff;
  margin-right: 15px;
  margin-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
}
.location-title-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  font-family: SfProText-Regular;
  color: #241c2d;
}
.location-address-text {
  font-weight: 400;
  font-size: 17px;
  font-family: SfProText-Regular;
  color: #241c2d;
  opacity: 0.6;
}
.location-calender-subdiv {
  margin-right: 13px;
  margin-top: 3px;
}
.description-text {
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  opacity: 0.6;
  font-family: SfProText-Regular;
  color: #241c2d;
  margin-bottom: 15px;
}
/* footer css  */
.footer-main {
  background-color: #150b1d;
  height: 50px;
  display: flex;
  align-items: center;
}

.left-text {
  font-weight: 400;
  font-size: 15px;
  font-family: SfProText-Regular;
  color: white;
  margin-left: 30px;
  margin-top: 10px;
}

.right-text {
  margin-right: 30px;
}

.footer-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.places-tile-button {
  background: #f0e9ff;
  color: #6c27ff;
  border-radius: 100px;
  font-family: SfProText-Regular;
  border: none;
  font-size: 17px;
  padding: 15px;
  width: 100%;
  line-height: 20px;
  margin-top: 15px;
}
.footer-mainlayout {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: -50px;
}
.plan-h{
  height: inherit;
}
/* not sign up modal */
.not-sign-up-modal .second-text{
  font-weight: 400;
font-size: 15px;
line-height: 21px;
text-align: center;
letter-spacing: -0.0024em;
color: #9F9BA3;
margin-top: 6px;
}
.not-sign-up-modal .not-sign-up{
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  margin-top: 20px;
color: var(--black);
}
.not-sign-up-modal .not-sign-up-wrap{
  width: 50%;
  margin: auto;
}
