.modal-scrollable {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    animation-name: modal-animation;
    animation-duration: 0.3s;
    z-index: 999999;
    animation-timing-function: ease-out;
  }
  
  .modal-content-scrollable {
    /* background-color: #fff;
    border-radius: 4px;
    padding: 16px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto; */
    background: linear-gradient( 180deg, #ddc4ff 0%, rgba(255, 255, 255, 0) 12.79% );
    background-color: white;
    border-radius: 35px;
    width: 70%;
    max-width: 390px;
    margin: auto;
  }
  
  .modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  
  .modal-body-scrollable {
    /* margin-top: 16px; */
    /* border-radius: 35px 0; */
  }
  